@font-face {
  font-family: "FWD_Circular";
  font-weight: 450;
  src: url("./font/FWD_Circular/FWDCircularTT-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "FWDCircularTT";
  font-weight: normal;
  src: url("./font/FWD_Circular/FWDCircularTT-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "FWD_Circular";
  font-weight: bold;
  src: url("./font/FWD_Circular/FWDCircularTT-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FWD_Circular";
  font-weight: 700;
  src: url("./font/FWD_Circular/FWDCircularTT-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FWD_Circular";
  font-weight: lighter;
  src: url("./font/FWD_Circular/FWDCircularTT-Book.ttf") format("truetype");
}

@font-face {
  font-family: "FWD_Circular";
  font-weight: 300;
  src: url("./font/FWD_Circular/FWDCircularTT-Book.ttf") format("truetype");
}



@font-face {
  font-family: "Noto_Sans";
  font-weight: 450;
  src: url("./font/Noto_Sans/NotoSans-Medium.ttf") format("truetype");
}


@font-face {
  font-family: "Noto_Sans";
  font-weight: bold;
  src: url("./font/Noto_Sans/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto_Sans";
  font-weight: 700;
  src: url("./font/Noto_Sans/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto_Sans";
  font-weight: lighter;
  src: url("./font/Noto_Sans/NotoSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Noto_Sans";
  font-weight: 300;
  src: url("./font/Noto_Sans/NotoSans-Light.ttf") format("truetype");
}


@font-face {
  font-family: "NotoSansThai";
  font-weight: 450;
  src: url("./font/NotoSansThai/NotoSansThai-Medium.ttf") format("truetype");
}


@font-face {
  font-family: "NotoSansThai";
  font-weight: bold;
  src: url("./font/NotoSansThai/NotoSansThai-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansThai";
  font-weight: 700;
  src: url("./font/NotoSansThai/NotoSansThai-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansThai";
  font-weight: lighter;
  src: url("./font/NotoSansThai/NotoSansThai-Light.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansThai";
  font-weight: 300;
  src: url("./font/NotoSansThai/NotoSansThai-Light.ttf") format("truetype");
}


@font-face {
  font-family: "FWDCircularVietWeb";
  font-weight: 450;
  src: url("./font/FWDCircularVietWeb/FWDCircularVietWeb-Medium.woff") format("woff");
}


@font-face {
  font-family: "FWDCircularVietWeb";
  font-weight: bold;
  src: url("./font/FWDCircularVietWeb/FWDCircularVietWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "FWDCircularVietWeb";
  font-weight: 700;
  src: url("./font/FWDCircularVietWeb/FWDCircularVietWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "FWDCircularVietWeb";
  font-weight: lighter;
  src: url("./font/FWDCircularVietWeb/FWDCircularVietWeb-Book.woff") format("woff");
}

@font-face {
  font-family: "FWDCircularVietWeb";
  font-weight: 300;
  src: url("./font/FWDCircularVietWeb/FWDCircularVietWeb-Book.woff") format("woff");
}

html {
  overflow-x: hidden;
  height: 100%;
}

body {
  margin: 0;
  font-family: "FWD_Circular", "Arial", "Helvetica Neue", sans-serif;
  /* font-family: "Arial", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll !important;
  height: 100%;
}


body :lang(th) {
  font-family: "NotoSansThai", "Noto_Sans", "FWD_Circular", "Arial", "Helvetica Neue", sans-serif;
}

body :lang(vi) {
  font-family: "FWDCircularVietWeb", sans-serif !important;
}

#root {
  /* overflow-x: hidden;
  height: 100%; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

* {
  font-family: "FWD_Circular", "Arial", "Helvetica Neue", sans-serif;
}

*:lang(th) {
  font-family: "NotoSansThai", "Noto_Sans", "FWD_Circular", "Arial", "Helvetica Neue", sans-serif;
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* 
@keyframes move {
  from {
    motion-offset: 0%; 
    offset-distance: 0%; 
  }

  to {
    motion-offset: 100%; 
    offset-distance: 100%; 
  }
}

@keyframes whoosh {
  from {
    motion-offset: 0%;
  }
  to {
    motion-offset: 100%;
  }
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}